<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">自然环境监测工作情况记录</span>
                <div class="button_list">
                    <sn-button :snButton="snButton"
                               :class="{ showButton: !saveButton }"
                               @handleChange="handleChange"></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form ref="formdata"
                     :inline="true"
                     :model="formdata.dataDic"
                     :rules="rules"
                     class="demo-form-inline form_row"
                     size="small"
                     label-width="200px">
                <el-form-item prop="ZRHJJCXMBH"
                              label="自然环境监测项目编号"
                              class="from-item-block">
                    <el-input v-model="formdata.dataDic.ZRHJJCXMBH"
                              placeholder="一个或一组相关联的监测对象类型的监测形成一个监测项目, 不得重复"
                              :disabled="!isEdit"
                              :maxlength="50"></el-input>
                </el-form-item>
                <el-form-item prop="ZRHJJCXMNR"
                              label="自然环境监测项目内容"
                              class="from-item-block">
                    <el-input v-model="formdata.dataDic.ZRHJJCXMNR"
                              resize="none"
                              type="textarea"
                              placeholder="实施目的、范围的简要说明"
                              :disabled="!isEdit"
                              :autosize="{ minRows: 2, maxRows: 6 }"
                              :maxlength="500"></el-input>
                </el-form-item>
                <el-form-item prop="JCDXLX"
                              label="监测对象类型（大类）"
                              class="from-item-block">
                    <el-select v-model="formdata.dataDic.JCDXLX"
                               @change="changejcdx"
                               :disabled="!isEdit">
                        <el-option v-for="(item, index) in ZRHJJCDXList"
                                   :key="`${index}`"
                                   :value="item.ID"
                                   :label="item.LBMC"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="JCDXLXXL"
                              label="监测对象类型（小类）"
                              class="from-item-block">
                    <el-select v-model="JCDXLXXLValue"
                               multiple
                               :disabled="!isEdit"
                               @change="changeJcdxlxxl">
                        <el-option v-for="item in ZRHJJCDXXLList"
                                   :key="item.ID"
                                   :label="item.LBMC"
                                   :value="item.ID">
                            <span :title="item.LBMC">{{ item.LBMC }}</span>
                        </el-option>
                    </el-select>
                </el-form-item>
                <!-- 获得设备列表 返回设备名称 -->
                <el-form-item prop="JCDW"
                              label="监测点位"
                              class="from-item-block">
                    <el-select v-model="formdata.dataDic.JCDW"
                               :disabled="!isEdit"
                               filterable
                               clearable>
                        <el-option v-for="(item, index) in EqeuitmentList"
                                   :key="`${index}`"
                                   :value="item.ID"
                                   :label="item.SBMC"
                                   :disabled="!isEdit">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="JCQSSJ"
                              label="监测起始时间"
                              class="from-item-block">
                    <el-date-picker v-model="formdata.dataDic.JCQSSJ"
                                    type="date"
                                    value-format="yyyy-MM-dd"
                                    :disabled="!isEdit"></el-date-picker>
                </el-form-item>
                <el-form-item prop="JCJSSJ"
                              label="监测结束时间"
                              class="from-item-block">
                    <el-date-picker v-model="formdata.dataDic.JCJSSJ"
                                    type="date"
                                    value-format="yyyy-MM-dd"
                                    :disabled="!isEdit"></el-date-picker>
                </el-form-item>
                <el-form-item prop="JCFF"
                              label="监测方法"
                              class="from-item-block">
                    <el-select v-model="formdata.dataDic.JCFF"
                               filterable
                               clearable
                               :disabled="!isEdit">
                        <el-option v-for="(item, index) in jcffData"
                                   :key="`${index}`"
                                   :value="item.CODE"
                                   :label="item.LABEL"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="SJCJDBH"
                              label="数据采集点编号"
                              class="from-item-block">
                    <el-input v-model="formdata.dataDic.SJCJDBH"
                              :disabled="!isEdit"
                              :maxlength="50"></el-input>
                </el-form-item>
                <el-form-item prop="JCZQ"
                              label="监测周期"
                              class="from-item-block">
                    <el-select v-model="formdata.dataDic.JCZQ"
                               filterable
                               clearable
                               :disabled="!isEdit">
                        <el-option v-for="(item, index) in jczqData"
                                   :key="`${index}`"
                                   :value="item.CODE"
                                   :label="item.LABEL"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="SSJG"
                              label="实施机构"
                              class="from-item-block">
                    <el-input v-model="formdata.dataDic.SSJG"
                              placeholder="实施该项监测的机构, 本单位实施时填部门名称, 外包给其他单位时填单位名称"
                              :disabled="!isEdit"
                              :maxlength="50"></el-input>
                </el-form-item>
                <el-form-item prop="JCSJKSYFW"
                              label="监测数据可使用范围"
                              class="from-item-block">
                    <el-select v-model="formdata.dataDic.JCSJKSYFW"
                               filterable
                               clearable
                               :disabled="!isEdit">
                        <el-option v-for="(item, index) in jcsjksyfwData"
                                   :key="`${index}`"
                                   :value="item.CODE"
                                   :label="item.LABEL"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="JCJLBCDD"
                              label="监测记录保存地点"
                              class="from-item-block">
                    <el-select v-model="formdata.dataDic.JCJLBCDD"
                               filterable
                               clearable
                               :disabled="!isEdit">
                        <el-option v-for="(item, index) in jcjlbcddData"
                                   :key="`${index}`"
                                   :value="item.CODE"
                                   :label="item.LABEL"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="JCJLBCSJ"
                              label="监测记录保存时间"
                              class="from-item-block">
                    <el-select v-model="formdata.dataDic.JCJLBCSJ"
                               filterable
                               clearable
                               :disabled="!isEdit">
                        <el-option v-for="(item, index) in jcjlbcsjData"
                                   :key="`${index}`"
                                   :value="item.CODE"
                                   :label="item.LABEL"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import infoMixin from "../0_com_js/info_mixin.js";
export default {
    name: "zrhjjcgzqkjl",
    mixins: [infoMixin],
    data () {
        return {
            isEdit: false,
            saveButton: true,
            formdata: {
                itemCode: "801",
                dataDic: {
                    glycbtid: this.heritageId,
                    ZRHJJCXMBH: "",
                    ZRHJJCXMNR: "",
                    JCDXLX: "",
                    JCDXLXXL: "",
                    JCDW: "",
                    QTJCDXLX: "",
                    JCQSSJ: "",
                    JCJSSJ: "",
                    JCFF: "",
                    SJCJDBH: "",
                    JCZQ: "",
                    SSJG: "",
                    JCSJKSYFW: "",
                    JCJLBCDD: "",
                    JCJLBCSJ: "",
                },
            },
            rules: {
                ZRHJJCXMBH: [
                    {
                        required: true,
                        message: "请填写自然环境监测项目编号",
                        trigger: "blur",
                    },
                ],
                JCZQ: [
                    {
                        required: true,
                        message: "请选择监测周期",
                        trigger: "change",
                    },
                ],
                SSJG: [
                    {
                        required: true,
                        message: "请填写实施机构",
                        trigger: "blur",
                    },
                ],
            },
            jcffData: [],
            jczqData: [],
            jcsjksyfwData: [],
            jcjlbcddData: [],
            jcjlbcsjData: [],
            jcdxlxData: [
                { id: "0", name: "大气" },
                { id: "1", name: "土壤" },
                { id: "2", name: "地表水" },
                { id: "3", name: "地下水" },
                { id: "4", name: "噪声" },
                { id: "5", name: "生物" },
                { id: "6", name: "其他" },
            ],
            jcxxList: [],
            EqeuitmentList: [],
            ZRHJJCDXList: [],
            ZRHJJCDXXLList: [],
            JCDXLXXLValue: [], //
        };
    },
    watch: {
        "formdata.dataDic": {
            deep: true,
            handler: function () {
                if (this.formdata.dataDic.JCDXLXXL) {
                    this.JCDXLXXLValue = this.formdata.dataDic.JCDXLXXL.split(
                        ","
                    );
                }
                if (this.formdata.dataDic.JCDXLX) {
                    this.ZRHJJCDXList.forEach((v) => {
                        if (v.ID == this.formdata.dataDic.JCDXLX) {
                            this.ZRHJJCDXXLList = v.CHILDREN;
                        }
                    });
                }
            },
        },
    },
    created () {
        this.GetEnum();
        this.getEqeuitmentList();
        this.getGetZRHJJCDXListTree();
    },
    mounted () {
    },
    methods: {
        ...mapActions([
            "getAllEnumInfobytableID",
            "GetGLXList",
            "GetZRHJJCDXListTree",
        ]),
        GetDataNext () {
            this.getEqeuitmentList();
        },
        async GetEnum () {
            let res = await this.getAllEnumInfobytableID({
                bid: "801",
            });
            let enumList = res || [];
            enumList.forEach((v) => {
                if (v.COLUMNID === "JCFF") {
                    this.jcffData = v.dataItems;
                }
                if (v.COLUMNID === "JCZQ") {
                    this.jczqData = v.dataItems;
                }
                if (v.COLUMNID === "JCSJKSYFW") {
                    this.jcsjksyfwData = v.dataItems;
                }
                if (v.COLUMNID === "JCJLBCDD") {
                    this.jcjlbcddData = v.dataItems;
                }
                if (v.COLUMNID === "JCJLBCSJ") {
                    this.jcjlbcsjData = v.dataItems;
                }
            });
        },
        changejcdx (value) {
            this.formdata.dataDic.JCDW = ""
            this.formdata.dataDic.JCDXLXXL = "";
            this.JCDXLXXLValue = [];
            this.ZRHJJCDXList.forEach((v) => {
                if (v.ID == value) {
                    this.ZRHJJCDXXLList = v.CHILDREN;
                }
            });
            this.getEqeuitmentList()
        },
        // 获得自然环境监测设备列表
        async getEqeuitmentList () {
            let lm = ""
            let str = ""
            switch (this.formdata.dataDic.JCDXLX) {
                case "1"://大气
                    lm = "50002592-c52a-4286-b6cd-8fb4065bf222,c815ce51-bf70-4297-8e80-de4fbc32d131"
                    break;
                case "2"://土壤
                    lm = "0778fe4f-cf12-444c-b66f-6cf84acb0df5"
                    break;
                case "3"://地表水
                case "4"://地下水
                    lm = "1db446ea-793b-4bef-b885-0f893d4608c3"
                    break;
                case "5"://噪声
                    lm = ""
                    break;
                case "6"://生物
                    lm = "351ee3bb-9c3c-473b-a9e4-2a6218749a97"
                    break;
                case "7"://其他
                    lm =
                        "50002592-c52a-4286-b6cd-8fb4065bf222,c815ce51-bf70-4297-8e80-de4fbc32d131,0778fe4f-cf12-444c-b66f-6cf84acb0df5,1db446ea-793b-4bef-b885-0f893d4608c3,351ee3bb-9c3c-473b-a9e4-2a6218749a97,7cb5eda5-bc0f-48c3-8dce-df3cb3489fac,956a05bd-b31b-41d7-b436-b03505369645"
                    break;
            }
            // 02274b72-92b9-11eb-b3ce-000c2977b7fd	摄像机
            // 0778fe4f-cf12-444c-b66f-6cf84acb0df5	土壤墒情监测站
            // 1db446ea-793b-4bef-b885-0f893d4608c3	水质五参数监测器
            // 351ee3bb-9c3c-473b-a9e4-2a6218749a97	动物多样性监测器
            // 50002592-c52a-4286-b6cd-8fb4065bf222	高精自动气象站
            // 7cb5eda5-bc0f-48c3-8dce-df3cb3489fac	物联网自动虫情信息采集设备
            // 956a05bd-b31b-41d7-b436-b03505369645	生态监测球机
            // c815ce51-bf70-4297-8e80-de4fbc32d131	一体式气象站
            let res = await this.GetGLXList({
                itemcode: "sbqd",
                lm: lm,
            });
            this.EqeuitmentList = res.ResultValue || [];
        },
        // 获得 自然环境监测对象 列表
        async getGetZRHJJCDXListTree () {
            let res = await this.GetZRHJJCDXListTree();
            this.ZRHJJCDXList = res.ResultValue || [];
        },
        changeJcdxlxxl () {
            this.formdata.dataDic.JCDXLXXL = this.JCDXLXXLValue.join(",");
        },
    },
};
</script>

<style>
.yearBox .yearItem {
    width: 48% !important;
}
.yearBox .separate {
    padding: 0 10px;
}
</style>
